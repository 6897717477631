<template>
  <div class="hello">
    <!-- <div id="map"></div> -->
    <!-- <div id="geocoder" class="geocoder"></div> -->
    <div v-if="!isLoading" id="map"></div>
    <div id="map" v-else><h1 style="text-align: center; margin-top:100px;color:#FFFFFF;font-size:25px;">Loading....</h1></div>
  </div>
</template>
<script src="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.js"></script>
<script src="https://api.tiles.mapbox.com/mapbox-gl-js/v1.6.0/mapbox-gl.js"></script>
<script>
import Vue from "vue";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import Engon from "./../assets/engineon.jpg";
import Engoff from "./../assets/engineoff.png";
import { mapGetters } from "vuex";
import Eventemit from "../components/eventemit";
// import Client  from "../components/Clients.vue";

// import this.oledDevices
  //  from "../components/oled_list.json";
export default {
  name: "Routemap",
  props: [
    "data",
    "starttime",
    "endtime",
    "isPlay",
    "passdata",
    "faildata",
    "engineondata",
    "engineoffdata"
  ],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")).clientddetails,
      marks: null,
      fs: [],
      distancetravelled:0,
      isLoading:false,
    };
  },
  computed:{
       ...mapGetters(['oledDevices'])
  },
  methods: {
    
    distance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist;
      }
    },
    init(alldata, passdatas, faildatas, engondatas, engoffdatas) {
      const size = 150;
      const passDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function() {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // Call once before every frame where the icon will be used.
        render: function() {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(41, 241, 195, ${1 - t})`;
          context.fill();

          // Draw the inner circle.
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = "green";
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint();

          // Return `true` to let the map know that the image was updated.
          return true;
        }
      };
      const failDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function() {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // Call once before every frame where the icon will be used.
        render: function() {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(255, 0, 71, ${1 - t})`;
          context.fill();

          // Draw the inner circle.
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = "red";
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint();
          // Return `true` to let the map know that the image was updated.
          return true;
        }
      };
      // const size = 200;
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
      var map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: alldata[0].geometry.coordinates[0],
        zoom: 16
      });
      map.on("load", function() {
        map.addImage("pass-dot", passDot, { pixelRatio: 2 });
        map.addImage("fail-dot", failDot, { pixelRatio: 2 });
        map.loadImage(Engon, (error, image) => {
          if (error) throw error;
          map.addImage("engon-dot", image);
        });
        map.loadImage(Engoff, (error, image) => {
          if (error) throw error;
          map.addImage("engoff-dot", image);
        });
        map.addSource("pass-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: passdatas
          }
        });
        map.addSource("fail-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: faildatas
          }
        });
        map.addSource("engon-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: engondatas
          }
        });
        map.addSource("engoff-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: engoffdatas
          }
        });
        map.addSource("route", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: alldata
          }
        });
        const marker = new mapboxgl.Marker({
          color: "#F84C4C"
        });
        map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6
          }
        });
        map.addLayer({
          id: "layer-with-pulsing-dot",
          type: "symbol",
          source: "pass-point",
          layout: {
            "icon-image": "pass-dot"
          }
        });
        map.addLayer({
          id: "layer-with-pulsing-dot2",
          type: "symbol",
          source: "fail-point",
          layout: {
            "icon-image": "fail-dot"
          }
        });
        map.addLayer({
          id: "layer-with-pulsing-dot3",
          type: "symbol",
          source: "engon-point",
          layout: {
            "icon-image": "engon-dot",
            "icon-size": 0.12
          }
        });
        map.addLayer({
          id: "layer-with-pulsing-dot4",
          type: "symbol",
          source: "engoff-point",
          layout: {
            "icon-image": "engoff-dot",
            "icon-size": 0.12
          }
        });
        alldata.forEach((el, index) => {
          setTimeout(() => {
            map.setCenter(el.geometry.coordinates[0]);
            marker.setLngLat(el.geometry.coordinates[0]);
            marker.addTo(map);
            this.isLoading = false;
          }, index * 700);
        });
      });
    }
  },
  beforeMount() {
     this.$store.dispatch('fetchOLEDDevices');
    this.user = JSON.parse(
      localStorage.getItem("user")
    ).clientddetails;
  },
  mounted() {
      var allArray = [];
      var passArray = [];
      var failArray = [];
      var engonArray = [];
      var engoffArray = [];
      this.isLoading=true;
      if (this.oledDevices
  .filter((value) => value.sno ==this.data[0].sno).length ==0) {
         Vue.axios
        .get(
          "https://robopower.xyz/app/v2data/getallpingsv2?devId=" +
            this.data[0].devId+"&from="+moment(this.starttime).format(
                "MM/DD/YYYY HH:MM:SS"
              )+"&to="+moment(this.endtime).format("MM/DD/YYYY HH:MM:SS")
        )
        .then(response => {
          if(response.data){
          var datan = response.data;
          datan.map(element => {
            if (datan.length != datan.indexOf(element) + 1) {
              var starttime = moment(this.starttime).format(
                "MM/DD/YYYY HH:MM:SS"
              );
              var endtime = moment(this.endtime).format("MM/DD/YYYY HH:MM:SS");
              var nextindex = datan.indexOf(element) + 1;
              if (
                element.gtime > starttime &&
                element.gtime < endtime &&
                element.lat != "0.000000" &&
                element.lon != "0.000000" &&
                datan[nextindex].lat != "0.000000" &&
                datan[nextindex].lon != "0.000000" &&
                element.lat != "NA" &&
                element.lon != "NA" &&
                datan[nextindex].lat != "NA" &&
                datan[nextindex].lon != "NA"
              ) {
                var distance = this.distance(
                  parseFloat(element.lat),
                  parseFloat(element.lon),
                  parseFloat(datan[nextindex].lat),
                  parseFloat(datan[nextindex].lon),
                  "K"
                );
                var coords = [
                  [parseFloat(element.lon), parseFloat(element.lat)],
                  [
                    parseFloat(datan[nextindex].lon),
                    parseFloat(datan[nextindex].lat)
                  ]
                ];
                this.distancetravelled += distance;
                if (distance > 0.3) {
                  allArray.push({
                    type: "Feature",
                    properties: {
                      color: "red" // red
                    },
                    geometry: {
                      type: "LineString",
                      coordinates: coords
                    }
                  });
                } else {
                  allArray.push({
                    type: "Feature",
                    properties: {
                      color: "green" // red
                    },
                    geometry: {
                      type: "LineString",
                      coordinates: coords
                    }
                  });
                }
              }
            }
          });
          this.passdata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            passArray.push(ob);
          });
          this.faildata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            failArray.push(ob);
          });
          this.engineondata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            engonArray.push(ob);
          });
          this.engineoffdata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            engoffArray.push(ob);
          });
          if (allArray.length <= 0) {
            this.$emit("routeclosed");
            this.isLoading=true;
      this.map.remove(); // Remove the map instance
        // Clear the contents of the "map" element
        const mapElement = document.getElementById("map");
        if (mapElement) {
          mapElement.innerHTML = "";
        }
          } else {
            Eventemit.$emit("distancetravelled",{ distance: this.distancetravelled, loading: this.isLoading });
            this.init(allArray, passArray, failArray, engonArray, engoffArray);
          }
          } else{
            this.$emit("routeclosed")
          }
          // requestAnimationFrame(this.animateMarker);
        })
        
        .catch(error => {
          console.log("Error: ", error);
        });
        

      } else{
      Vue.axios
        .get(
        "https://robopower.xyz/app/v2data/getallpingsv22?cbid=" +
            this.data[0].cbid+"&from="+moment(this.starttime).format(
                "MM/DD/YYYY HH:MM:SS"
              )+"&to="+moment(this.endtime).format("MM/DD/YYYY HH:MM:SS")
        )
        .then(response => {
          if(response.data){
          var datan = response.data;
          datan.map(element => {
            if (datan.length != datan.indexOf(element) + 1) {
              var starttime = moment(this.starttime).format(
                "MM/DD/YYYY HH:MM:SS" 
              );
              var endtime = moment(this.endtime).format("MM/DD/YYYY HH:MM:SS");
              var nextindex = datan.indexOf(element) + 1;
              if (
                element.gtime > starttime &&
                element.gtime < endtime &&
                element.lat != "0.000000" &&
                element.lon != "0.000000" &&
                datan[nextindex].lat != "0.000000" &&
                datan[nextindex].lon != "0.000000" &&
                element.lat != "NA" &&
                element.lon != "NA" &&
                datan[nextindex].lat != "NA" &&
                datan[nextindex].lon != "NA"
              ) {
                var distance = this.distance(
                  parseFloat(element.lat),
                  parseFloat(element.lon),
                  parseFloat(datan[nextindex].lat),
                  parseFloat(datan[nextindex].lon),
                  "K"
                );
                var coords = [
                  [parseFloat(element.lon), parseFloat(element.lat)],
                  [
                    parseFloat(datan[nextindex].lon),
                    parseFloat(datan[nextindex].lat)
                  ]
                ];
                    this.distancetravelled += distance ;
                // if(distance>0.2){
                if (distance > 0.3) {
               
                  allArray.push({
                    type: "Feature",
                    properties: {
                      color: "red" // red
                    },
                    geometry: {
                      type: "LineString",
                      coordinates: coords
                    }
                  });
                } else {
                  allArray.push({
                    type: "Feature",
                    properties: {
                      color: "green" // red
                    },
                    geometry: {
                      type: "LineString",
                      coordinates: coords
                    }
                  });
                }
              // } else{
              //   allArray.push({
              //       type: "Feature",
              //       properties: {
              //         color: "red" // red
              //       },
              //       geometry: {
              //         type: "LineString",
              //         coordinates: coords
              //       }
              //     });
              // }
              }
            }
          });
          this.passdata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            passArray.push(ob);
          });
          
          this.faildata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            failArray.push(ob);
          });
          this.engineondata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            engonArray.push(ob);
          });
          this.engineoffdata.map(res => {
            var ob = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [parseFloat(res.lon), parseFloat(res.lat)] // icon position [lng, lat]
              }
            };
            engoffArray.push(ob);
          });
          if (allArray.length <= 0) {
            this.$emit("routeclosed");
            this.isLoading=true;
          this.map.remove(); // Remove the map instance
        // Clear the contents of the "map" element
          const mapElement = document.getElementById("map");
          if (mapElement) {
            mapElement.innerHTML = "";
          }
          } else {
            Eventemit.$emit("distancetravelled",{ distance: this.distancetravelled, loading: this.isLoading });
            this.init(allArray, passArray, failArray, engonArray, engoffArray);
          }
          // requestAnimationFrame(this.animateMarker);
        } else {
           this.$emit("routeclosed");
        }
     
        })
        .catch(error => {
          console.log("Error: ", error);
        });
      }
  }
};
</script>

<style scoped>
#map {
  height: 300px;
  width: 100%;
}
.overlay {
  position: absolute;
  top: 10px;
  left: 10px;
}

.overlay button {
  font: 600 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #3386c0;
  color: #fff;
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.overlay button:hover {
  background-color: #4ea0da;
}
</style>
